import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App'
import { Web3Provider } from './components/Web3Provider'
import './index.css'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
	<Web3Provider>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Web3Provider>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
